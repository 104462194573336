exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-working-events-js": () => import("./../../../src/pages/working-events.js" /* webpackChunkName: "component---src-pages-working-events-js" */),
  "component---src-templates-basic-page-basic-page-js": () => import("./../../../src/templates/basic-page/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-basic-page-js" */),
  "component---src-templates-blogs-blog-post-js": () => import("./../../../src/templates/blogs/blog-post.js" /* webpackChunkName: "component---src-templates-blogs-blog-post-js" */),
  "component---src-templates-events-event-js": () => import("./../../../src/templates/events/event.js" /* webpackChunkName: "component---src-templates-events-event-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/landing-page/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */)
}

